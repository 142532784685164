.App {
  z-index: 10;
}

.things {
  box-shadow: inset 0px 0px 18px 0px rgba(0, 0, 0, 0.3);
}

.App-body {
  /* background-color: #2E3440; */
  /* z-index: 0; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  /* align-items: center; */
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-noto {
  font-family: 'Noto Sans', sans-serif;
}

.Route {
  color: "black"
}

.MaterialUiTab {
  color: #FFF;
  text-decoration: none;
}

.MaterialUiTab:hover {
  color: #131313;
  text-decoration: none;
}

.sketch-container {
  position: fixed;
  z-index: -1;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
}
